import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { GlobalDataContext } from "../../context/context";
import BaseLayout from "../global/BaseLayout";
import TransparentHeader from "../global/TransparentHeader";
import CounterUp from '../global/CounterUp'
import Values from "../About/Values2";
import Map from "../Contact/MapContent";
// import { ButtonContent } from "../global/boton/ButtonContent";

function ProductDetail({ phone }) {
  const { rpdata } = useContext(GlobalDataContext);
  const { id } = useParams();

  const fondo = "https://firebasestorage.googleapis.com/v0/b/archivos-fc4bd.appspot.com/o/imagenes_fondos%2Ffondo%20ferreteria.jpg?alt=media&token=df07b60f-ef12-4227-abce-923247f14ff3";

  return (
    <BaseLayout PageName={`${rpdata?.dbPrincipal?.name}`}>
      {
        rpdata?.dbServices?.map((item, index) => {
          if (item.name.replaceAll(/[\^*@!"#$%&/()=?¡!¿'\\ ]/g, "-").toLowerCase() === id) {
            return (
              <div key={index}>
                <TransparentHeader
                  // imagen aleatoria
                  bgimg={
                    rpdata?.gallery?.length > 1
                      ? rpdata?.gallery[
                          Math.floor(Math.random() * rpdata?.stock?.length)
                        ]
                      : rpdata?.stock[
                          Math.floor(Math.random() * rpdata?.stock?.length)
                        ]
                  }
                  headertitle={item.name}
                  Subheader={"Our Products"}
                />
                {/*  */}
                <div
                  className="flex flex-wrap justify-center my-10 gap-3 px-5 items-center productfondo"
                  style={{ backgroundImage: `url("${fondo}")` }}
                >
                  {/* inicio de tabla producto */}
                  <div className="relative overflow-x-auto shadow-md sm:rounded-lg mx-5 bg-slate-600">
                    <table className="w-full text-sm text-left rtl:text-right text-white">
                      <thead className="text-xs text-gray-700 uppercase dark:text-gray-400">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 text-white bg-red-500"
                          >
                            Item
                          </th>
                          <th
                            scope="col"
                            className="text-center px-6 py-3 text-white bg-red-500"
                          >
                            Description
                          </th>
                          {/* <th
                            scope="col"
                            className="px-6 py-3 text-white bg-red-500"
                          >
                            Quality
                          </th> */}
                          {/* <th
                            scope="col"
                            className="px-6 py-3 text-white bg-red-500"
                          >
                            Physi...........
                          </th> */}
                        </tr>
                      </thead>
                      {rpdata?.works?.map((works, index) => {
                        return works.service === item.name ? (
                          <>
                            <tbody>
                              <tr className="border-b border-gray-700">
                                <th
                                  scope="row"
                                  className="px-6 py-4 font-medium whitespace-nowrap"
                                > {works.name}</th>
                                <td className="px-6 py-4">{works.description}</td>
                                {/* <td className="px-6 py-4">{works.subdescription}</td> */}
                                {/* <td className="px-6 py-4">-------</td> */}
                              </tr>
                            </tbody>
                          </>
                        ) : null;
                      })}
                    </table>
                    
                  </div>
                  {/* <ButtonContent /> */}
                </div>
                {/*Fin Tabla Producto  */}
              </div>
            );
          } else {
            // Make sure to return a value for other cases
            return null; // You can return null or some other default value
          }
        })
      }
      <CounterUp image={rpdata?.gallery?.[6]} />

      <div className="mt-[-130px]">
        <Values />
      </div>
      <Map />

    </BaseLayout>
  );
}

export default ProductDetail;