import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
import BaseLayout from "../components/global/BaseLayout";
import Busqueda from "../components/Services/Busqueda";


function Products() {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <BaseLayout PageName="product">
            <div className="md:max-w-full w-full">
                <TransparentHeader
                    headertitle="Products"
                    Subheader="Products"
                    bgimg={`${rpdata?.gallery?.[2]}`}
                />
                <Busqueda />

            </div>
        </BaseLayout>
    );
}

export default Products;
